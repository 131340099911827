<template>

    <div class="w-full h-full px-3 py-4 relative">

        <All model="BackCompany" :immediate="true" :data.sync="companies"></All>

        <div class="h-full w-full flex flex-col">

            <div class="h-full flex flex-col pb-6 relative rounded-lg shadow-card p-2 mx-1 overflow-hidden" :class="'bg-module-'+mode">

                <div class="flex-1 min-h-0 flex flex-col">

                    <div class="h-8 flex flex-row jusity-start items-center">
                        <span v-for="(el,index) in options" :key="index" v-if="el.value == optionSelected && user.name == 'Agregado'" class="text-purple font-semibold ml-2">{{el.name}} <span v-if="deviceSelected || companySelected" class="text-sm font-medium" :class="'text-dfont-'+mode">: {{deviceSelected}}</span> <span v-for="(el,index) in companies" :key="index" v-if="companySelected == el.Id"  class="text-sm font-medium" :class="'text-dfont-'+mode">{{el.FrontName}}</span> </span>
                        <span v-for="(el,index) in optionsUser" :key="index" v-if="el.value == optionSelected && user.name != 'Agregado'" class="text-purple font-semibold ml-2">{{el.name}} <span v-if="deviceSelected || companySelected" class="text-sm font-medium" :class="'text-dfont-'+mode">: {{deviceSelected}}</span> <span v-for="(el,index) in companies" :key="index" v-if="companySelected == el.Id"  class="text-sm font-medium" :class="'text-dfont-'+mode">{{el.FrontName}}</span> </span>
                    </div>

                    <div v-if="user.name == 'Agregado'" class="h-auto">

                        <All model="AnalitycsAllUser" :immediate="false" ref="analityc" :query="config" v-slot="{data,loading}">

                            <div v-if="!loading" class="h-auto">

                                <div class="h-48 mt-2">
                                    <columnchart :data="data" chartId="chart"></columnchart>
                                </div>
                                

                            </div>

                            <div v-else class="h-48 mt-2 relative">
                                <loader :loading="loading"></loader>
                            </div>
                        
                        </All>

                    </div>

                    <div v-else class="h-auto">

                        <All model="AnalitycsUser" :immediate="false" ref="analityc" :query="configUser" v-slot="{data,loading}">

                            <div v-if="!loading" class="h-auto">

                                <div class="h-48 mt-2">
                                    <columnchart :data="data" chartId="chart"></columnchart>
                                </div>
                                

                            </div>

                            <div v-else class="h-48 mt-2 relative">
                                <loader :loading="loading"></loader>
                            </div>
                        
                        </All>

                    </div>

                    <div v-if="user.name == 'Agregado'" class="h-auto mt-4 ml-2 flex flex-col justify-center items-start">

                        <div v-for="(el,index) in optionsSelect" :key="index" class="h-auto flex flex-col mt-2" @click="optionSelected = el.value">

                            <div v-if="(userApp.rol.Name == 'Gerente') || userApp.rol.Name == 'Propiedad'" class="h-8 flex flex-row justify-start items-center">
                            
                                <i v-if="el.value == optionSelected" class="mdi mdi-checkbox-marked-circle-outline mr-2" :class="'text-dfont-'+mode"></i>
                                <i v-else class="mdi mdi-checkbox-blank-circle-outline mr-2" :class="'text-dfont-'+mode"></i>

                                <span v-if="userApp.rol.Name == 'Gerente'" :class="{'font-semibold': el.value == optionSelected, 'text-dfont-darkmode': mode == 'darkmode', 'text-dfont-whitemode': mode == 'whitemode'}">{{el.name}} <span v-if="el.value == 'companyHours'" class="font-semibold ">{{companyAnalitycs.Name}}</span></span>
                                <span v-else :class="{'font-semibold': el.value == optionSelected, 'text-dfont-darkmode': mode == 'darkmode', 'text-dfont-whitemode': mode == 'whitemode'}">{{el.name}}</span>

                            </div>

                            <div v-if="el.value == 'companyHours' && userApp.rol.Name != 'Gerente'" class="overflow-hidden" :class="{'h-0': optionSelected != 'companyHours', 'h-8': optionSelected == 'companyHours'}" style="transition: all .3s">

                                <All model="BackCompany" :immediate="true" v-slot="{data:datacompany,loading:loadingcompany}" :data.sync="companies">

                                    <div v-if="!loadingcompany" class="h-full flex flex-row justify-start items-center w-full">

                                        <span class="text-xs px-2" :class="'text-dfont-'+mode">{{$t('company')}}:</span>

                                        <div class="h-8 w-full">

                                            <div class="h-full w-full bg-lightgray rounded-lg flex flex-row justify-between items-center">

                                                <!-- <el-select v-model="companySelected" filterable :placeholder="$t('selectACompany')" class="w-full">

                                                    <el-option
                                                    v-for="item in datacompany"
                                                    :key="item.Id"
                                                    :label="item.FrontName"
                                                    :value="item.Id"
                                                    class="w-full">
                                                    </el-option>

                                                </el-select> -->

                                                <select name="companySelected" v-model="companySelected" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                                    <option v-for="(el,index) in datacompany" :key="index" :value="el.Id" :class="'text-dfont-'+mode">{{el.FrontName}}</option>
                                                </select>

                                            </div>

                                        </div>

                                    </div>

                                </All>

                            </div>

                            <div v-if="el.value == 'deviceHours'" class="overflow-hidden" :class="{'h-0': optionSelected != 'deviceHours', 'h-8': optionSelected == 'deviceHours'}" style="transition: all .3s">

                                <All model="Devices" :immediate="true" v-slot="{data:datadevice,loading:loadingdevice}">

                                    <div v-if="!loadingdevice" class="h-full flex flex-row justify-start items-center w-full">

                                        <div class="h-8 w-full">

                                            <div class="h-full w-full bg-lightgray rounded-lg flex flex-row justify-between items-center">

                                                <!-- <el-select v-model="deviceSelected" filterable :placeholder="$t('selectACompany')" class="w-full">

                                                    <el-option
                                                    v-for="item in datadevice"
                                                    :key="item.device"
                                                    :label="item.device"
                                                    :value="item.device"
                                                    class="w-full">
                                                    </el-option>

                                                </el-select> -->

                                                <select name="deviceSelected" v-model="deviceSelected" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                                    <option v-for="(el,index) in datadevice" :key="index" :value="el.device" :class="'text-dfont-'+mode">{{el.device}}</option>
                                                </select>

                                            </div>

                                        </div>

                                    </div>

                                </All>

                            </div>

                        </div>

                    </div>

                    <div v-else class="h-auto mt-4 ml-2 flex flex-col justify-center items-start">

                        <div v-for="(el,index) in optionsUser" :key="index" class="h-auto flex flex-col mt-2" @click="optionSelected = el.value">

                            <div class="h-8 flex flex-row justify-start items-center">
                            
                                <i v-if="el.value == optionSelected" class="mdi mdi-checkbox-marked-circle-outline mr-2" :class="'text-dfont-'+mode"></i>
                                <i v-else class="mdi mdi-checkbox-blank-circle-outline mr-2" :class="'text-dfont-'+mode"></i>

                                <span :class="{'font-semibold': el.value == optionSelected, 'text-dfont-darkmode': mode == 'darkmode', 'text-dfont-whitemode': mode == 'whitemode'}">{{el.name}}</span>

                            </div>

                            <div v-if="el.value == 'deviceHours'" class="overflow-hidden" :class="{'h-0': optionSelected != 'deviceHours', 'h-8': optionSelected == 'deviceHours'}" style="transition: all .3s">

                                <All model="Devices" :immediate="true" v-slot="{data:datadevice,loading:loadingdevice}">

                                    <div v-if="!loadingdevice" class="h-full flex flex-row justify-start items-center w-full">

                                        <div class="h-8 w-full">

                                            <div class="h-full w-full rounded-lg flex flex-row justify-between items-center" :class="'bg-box-'+mode">

                                                <!-- <el-select v-model="deviceSelected" filterable :placeholder="$t('selectACompany')" class="w-full">

                                                    <el-option
                                                    v-for="item in datadevice"
                                                    :key="item.device"
                                                    :label="item.device"
                                                    :value="item.device"
                                                    class="w-full">
                                                    </el-option>

                                                </el-select> -->

                                                <select name="deviceSelected" v-model="deviceSelected" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                                    <option v-for="(el,index) in datadevice" :key="index" :value="el.device" :class="'text-dfont-'+mode">{{el.device}}</option>
                                                </select>

                                            </div>

                                        </div>

                                    </div>

                                </All>

                            </div>

                        </div>

                    </div>

                    <div v-if="user.name != 'Agregado'" class="mt-8 rounded-lg p-2 flex flex-col justify-start" :class="'bg-box-'+mode">

                        <All model="UserApp" :immediate="true" :query="{id:user.id}" v-slot="{data:datauser,loading:loadinguser}">

                            <div v-if="!loadinguser" class="h-auto flex flex-col">

                                <span class="font-semibold" :class="'text-dfont-'+mode">{{ $t('userresume') }}</span>

                                <span class="text-sm ml-2" :class="'text-dfont-'+mode"><span class="font-semibold">{{ $t('username') }}:</span> {{datauser.name}} {{datauser.surname1}} {{datauser.surname2}}</span>
                                <span class="text-sm ml-2" :class="'text-dfont-'+mode"><span class="font-semibold">{{ $t('rol') }}:</span> {{datauser.rol.Name}}</span>
                                <span class="text-sm ml-2" :class="'text-dfont-'+mode"><span class="font-semibold">{{ $t('company') }}:</span> <span v-for="(el,index) in datauser.relation" :key="index"><span v-if="index>0">,</span> {{el.company.FrontName}}</span></span>
                                <span v-if="datauser.rol.Name == 'Representante'" class="text-sm ml-2" :class="'text-dfont-'+mode"><span class="font-semibold">{{ $t('agent') }}:</span> <span v-for="(el,index) in datauser.relation" :key="index"><span v-if="index>0">,</span> {{el.employeelink.employee.Name}}</span></span>
                                <span v-if="datauser.rol.Name == 'Comercial'" class="text-sm ml-2" :class="'text-dfont-'+mode"><span class="font-semibold">{{ $t('zones') }}:</span> <span v-for="(el,index) in datauser.relation" :key="index"><span v-if="index>0">,</span> {{el.zonelink.zone.Name}} <span class="text-xs">({{el.saletype.Name}})</span></span></span>

                            </div>

                        </All>

                    </div>

                </div>
                
            </div>

        </div>

    </div>

</template>

<script>
import basiclinechart from '../../components/basiclinechart.vue';
import { All, Request } from '@/api/components';
import { state, actions } from '@/store';
import user from '../../components/user.vue';
import loader from '../../components/loader.vue';
import Devices from '../../api/models/Devices';
import columnchart from '../../components/columnchart.vue';

export default {
    components:{
        basiclinechart,
        All,
        Request,
        user,
        loader,
        Devices,
        columnchart
    },
    data(){
        return{
            optionSelected:null,
            options:[
                {name:this.$t('allgrouphours'), value: null},
                {name:this.$t('hoursbycompany'), value: 'companyHours'},
                {name:this.$t('zonehours'), value: 'respZoneHours'},
                {name:this.$t('representanthours'), value: 'respHours'},
                {name:this.$t('devicehours'), value: 'deviceHours'}                
            ],
            optionsUser:[
                {name:this.$t('allhours'), value: null},
                {name:this.$t('devicehours'), value: 'deviceHours'},
            ],
            optionsGerente:[
                {name:this.$t('allhoursof'), value: 'companyHours'},
                {name:this.$t('zonehours'), value: 'respZoneHours'},
                {name:this.$t('representanthours'), value: 'respHours'},
                {name:this.$t('devicehours'), value: 'deviceHours'}                
            ],
            companySelected:null,
            deviceSelected: null,
            companies:null
        }
    },
    methods:{
        openFilters(){
            this.$router.push( {name:'filtersAnalitycs'} )
        },
        deviceName(device){
            switch (device) {
                case 'desktop':
                    return this.$t('pchours')
                    break;
                case 'mobile':
                    return this.$t('mobilehours')
                    break;
                case 'tablet':
                    return this.$t('tablethours')
                    break;
                case null:
                    return this.$t('allhours')
                    break;
                case 'companyHours':
                    return this.$t('hourbycompany')
                    break;
            }
        }
    },
    computed:{
        user(){
            return state.userAnalitycs
        },
        period(){
            return state.periodAnalitycs
        },
        config(){
            return{
                period: this.period,
                device: this.deviceSelected,
                company: this.companySelected,
                userLevel: this.level
            }
        },
        configUser(){
            return{
                period: this.period,
                device: this.deviceSelected,
                user:this.user.id
            }
        },
        level(){
            if(this.optionSelected == 'respZoneHours'){
                return 3
            } else if(this.optionSelected == 'respHours'){
                return 4
            } else {
                return null
            }
        },
        userApp(){
            return state.user
        },
        periodo(){
            switch (this.period) {
                case 'month':
                    return this.$t("monthly")
                    break;
                case 'quarter':
                    return this.$t("quarterly")
                    break;
                case 'year':
                    return this.$t("annual")
                    break;
            }
        },
        companyAnalitycs(){
            return state.companyAnalitycs
        },
        optionsSelect(){
            if(state.user.rol.Name == 'Gerente'){
                return this.optionsGerente
            } else{
                return this.options
            }
        },
        mode(){
            return state.mode
        }

    },
    watch:{
        period(){
            this.$refs['analityc'].request();
        },
        companySelected(){
            if(this.optionSelected == 'companyHours'){
                this.$refs['analityc'].request();
            } else {
            }
        },
        optionSelected(n,o){
            if(n != 'companyHours'){
                this.companySelected = null
            }
            this.deviceSelected = null
            if(n == 'deviceHours'){
                this.deviceSelected = 'mobile'
            }
            if(n == 'companyHours' && this.companySelected == null){
                this.companySelected = this.companies[0].Id
            }
            this.$refs['analityc'].request();
        },
        company(){
            this.deviceSelected = null
        },
        deviceSelected(){
            this.$refs['analityc'].request();
        },
        companyAnalitycs(n,o){
            this.companySelected = n.Id
        }
        
    },
    mounted(){

        if(state.companyAnalitycs)
        this.companySelected = state.companyAnalitycs.Id

        if(this.companySelected){
            this.optionSelected = 'companyHours'
        }

        // if(state.user.rol.Name == 'Gerente'){

        //     this.companySelected = state.idCompany;

        // }

        this.$refs['analityc'].request();

    }   
}
</script>